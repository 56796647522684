import { AsYouType } from 'libphonenumber-js';
import { i18n } from '@/packages/i18n';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

export default function baseFormValidators() {
  const isEmpty = (fieldName, fieldValue) => (!fieldValue ? `The ${fieldName} field is required` : '');

  const minLength = (fieldValue, min) => {
    // Todo: debounce the validation to give time for typing
      const { t } = i18n.global;

     return (fieldValue.length < min
      ? t('FormInput.error.minLengthErrorMsg', { min })
      : '');
     };
const isEmail = (fieldName, fieldValue) => {
    const { t } = i18n.global;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !regex.test(fieldValue)
      ? t('EmailField.error.emailAddressErrorMsg')
      : '';
  };

  const getMobileErrorMsg = (mobile, internationalCode) => {
    const { t } = i18n.global;
    const asYouType = new AsYouType(`${internationalCode}`);
    asYouType.input(`${mobile}`);
    return !asYouType.isValid()
      ? t('MobileField.error.mobileNumberErrorMsg')
      : '';
  };

  const getMobileCustomErrorMsg = (mobile, internationalCode) => {
    const { t } = i18n.global;

    const asYouType = new AsYouType(`${internationalCode}`);
    asYouType.input(mobile);

    const orderStore = useOrderFormStore();

    // If empty array then assume all of the countries are included
    const allowedCountriesForMobileNumber = orderStore.getPaymentProvider?.allowed_countries_for_mobile || [];
    if (
      allowedCountriesForMobileNumber.length > 0
      && !allowedCountriesForMobileNumber.includes(asYouType.getNumber().country)
    ) {
      return `${t('MobileField.error.mobileNumberErrorMsgForCountry')} ${allowedCountriesForMobileNumber.join(', ')}`;
    }
    return '';
  };

  return {
    isEmpty,
    minLength,
    isEmail,
    getMobileErrorMsg,
    getMobileCustomErrorMsg,
  };
}
