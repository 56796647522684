import { setGlobalContextProperty, readGlobalContextProperty, setUser as setDataDogUser } from './useDataDogTracking';

export const checkClarity = () => (!!window.clarity);

export const trackClarityTag = (key, value) => {
  if (checkClarity()) {
    window.clarity('set', key.toString(), value.toString());
  }
};

export const trackClarityEvent = (value) => {
  if (checkClarity()) {
    window.clarity('event', value.toString());
  }
};

export const trackTTS = (key, value) => {};

export const trackCustomer = (customerId) => {
  if (!customerId) {
    return;
  }

  setDataDogUser({ id: customerId });
};

export const trackOrderKey = (orderKey) => {

  if (!orderKey) {
    return;
  }

  let orderKeys = readGlobalContextProperty('orderKey') || [];

  if (!orderKeys.includes(orderKey)) {
    orderKeys = [...orderKeys, orderKey];
  }

  setGlobalContextProperty('orderKey', orderKeys);
  trackClarityTag('orderKey', orderKey);
};
