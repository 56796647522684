import { ref } from 'vue';

import { useOrderFormStore } from '@/stores/forms/orderFormStore';
import { useGlobalStore } from '@/stores/globalStore';
import { useBuyOrderFormStore } from '@/stores/forms/buyOrderFormStore';
import { useSellOrderFormStore } from '@/stores/forms/sellOrderFormStore';
import { useIdentityStore } from '@/stores/identityStore';

import useModalActions from '@/composables/store/modal/useModalActions';
import appConstants from '@/composables/helpers/appConstants';
import useApiHelpers from '@/composables/api/helpers';
import useOrderEndpoints from '@/composables/api/useOrderEndpoints';
import usePaymentFilters from '@/composables/filters/usePaymentFilters';

const { BANXA_URLS, AXNAB_URLS } = appConstants;
const { getRequest } = useApiHelpers();

export default function useAxnab() {
	const queueHasAxnab = ref(false);
	const axnabUrls = ['axnab.com', 'd1.banxa-dev.com', 's1.banxa-dev.com', 'axnab.banxa-preprod.com'];

	const toggleAxnabStorage = (isAxnab) => {
		localStorage.setItem('isAxnab', isAxnab);
	};

	const isAxnab = () => localStorage.getItem('isAxnab')
			|| axnabUrls.some((string) => window.location.hostname.includes(string));

	/**
	 * Handles the display of the Axnab modal.
	 * Activates the redirect modal and stores Axnab status in local storage.
	 */
	const handleAxnabModal = () => {
		const { toggleRedirectModal } = useModalActions();
		toggleRedirectModal(true);
	};

	const getLinkByHost = (key) => {
		if (isAxnab()) {
			return Object.hasOwnProperty.call(AXNAB_URLS, key) ? AXNAB_URLS[key] : '';
		}

		return Object.hasOwnProperty.call(BANXA_URLS, key) ? BANXA_URLS[key] : '';
	};

	/**
	 * Retrieves and processes the Axnab swap session based on the current URL's
	 * session parameter.
	 *
	 * @param {Object} identityStore - The identity store used to fetch the next identity route.
	 * @param {Object} route - The current route object.
	 * @param {Object} router - The router instance, used to navigate to the next route.
	 * @description Fetches the session details from the API and updates the active
	 * payment provider in the order store.
	 * Proceeds to the next identity route using the identity store.
	 */

	const getAxnabSwapSession = async (route, router) => {
		console.log('Session swap initiated');
		const { searchParams } = new URL(window.location.href);
		const sessionId = searchParams.get('session');
		const identityStore = useIdentityStore();
		const orderStore = useOrderFormStore();
		const globalStore = useGlobalStore();
		const buyOrderStore = useBuyOrderFormStore();
		const sellOrderStore = useSellOrderFormStore();
		const { getCoins } = useOrderEndpoints();
		const { filterPaymentProvider } = usePaymentFilters();

		await getRequest(`/swapSession/${sessionId}`, async (res) => {
			const { data: paymentCode } = res;
			const { data: { data: { orderType, coinType } } } = await getRequest('/summary', () => {}, { retry: true });

			const orderMode = orderType === 'CRYPTO-SELL' ? 'SELL' : 'BUY';
			const store = orderMode === 'SELL' ? sellOrderStore : buyOrderStore;
			orderStore.setActiveMode(orderMode);
			const { data: { data: { coins } } } = await getCoins(orderMode.toLowerCase());

			store.setCoinData(coins);
			store.setCoinType(coinType);

			await globalStore.fetchPayments();

			const { paymentType } = filterPaymentProvider(
				orderStore.getActiveStorePaymentData,
				paymentCode,
			);

			orderStore.setActiveModePaymentProvider(paymentCode.toLowerCase());
			orderStore.setPspTypeCode(paymentType.toLowerCase());
			toggleAxnabStorage(true);

			identityStore.fetchNextIdentityRoute(route).then((nextRoute) => {
				router.push(nextRoute);
			});
		});
	};

  /**
   * Fetches the Axnab redirect URL from the API endpoint.
   *
   * @returns {String} A promise that resolves to the redirect URL as a string.
   * @description Makes an API request to retrieve the redirect link for Axnab
	 * and returns the String response.
   */

	const getAxnabRedirectUrl = async () => {
		const res = await getRequest('/getAxnabRedirectLink');
		return res.data;
	};

	/**
	 * @param {Object} globalStore - The global store, used to determine if the user
	 * is within an iframe
	 * @decription Redirects the user to the Axnab environment.
	 * If the user is within an iframe, the parent window is redirected.
	 */

	const redirectToAxnab = async (globalStore) => {
		const redirectUrl = await getAxnabRedirectUrl(getRequest);
		if (globalStore.getIsInIFrame) {
      window.parent.location.replace(redirectUrl);
      return;
		}
		window.location.replace(redirectUrl);
	};

	/**
	 * @param {object, array} queueRes - The response from the queue.
	 * @returns {boolean} True if AXNAB is in the queue, false otherwise.
	 * @description Checks if AXNAB is in the queue. If there are no remaining docs
	 * from the API, it returns false. Otherwise, it sets the queueHasAxnab composable store value
	 * to true and returns true if AXNAB is in the queue, false otherwise.
	 */

	const hasAxnabInQueue = (queueRes) => {
		const noRemainingIDDocsFromApi = !queueRes || queueRes.length <= 0;
		if (noRemainingIDDocsFromApi) {
			return false;
		}
		queueHasAxnab.value = Object.keys(queueRes).includes('AXNAB');
		return Object.keys(queueRes).includes('AXNAB');
	};

	return {
		isAxnab,
		toggleAxnabStorage,
		handleAxnabModal,
		getLinkByHost,
		getAxnabSwapSession,
		hasAxnabInQueue,
		queueHasAxnab,
		redirectToAxnab,
	};
}
