/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

import { useOrderFormStore } from '@/stores/forms/orderFormStore';
import { useGlobalStore } from '@/stores/globalStore';
import { useVerificationStore } from '@/stores/forms/verificationFormStore';

import useLocalStorage from '@/composables/storage/useLocalStorage';
import useIdentityQueueEndpoints from '@/composables/api/useIdentityQueueEndpoints';
import useIdentityQueueHelpers from '@/composables/store/identity/useIdentityQueueHelpers';
import useIdentityProgressHelpers from '@/composables/store/identity/useIdentityProgressHelpers';
import useApiHelpers from '@/composables/api/helpers';
import useOrderEndpoints from '@/composables/api/useOrderEndpoints';
import { isExpressCheckoutEnabledForMerchAndOrder } from '@/composables/router/handleExpressCheckout';

import {
  INCOMPLETE_STATUES, IDENTITY_BLOCKERS, INCOMPLETE_PAYMENT_STATUES, useLegacyIdentity,
} from '@/composables/store/identity/constants';
import useAxnab from '@/composables/identity/useAxnab';

import { trackClarityEvent } from '@/composables/tracking/useTracking';

const { getPendingIdentityDocuments } = useIdentityQueueEndpoints();
const { checkLocalStorage, bindLocalStorage, removeLocalStorageByKey } = useLocalStorage();
const { organizeRoutes, sortRoutes, toResetIdentityQueue } = useIdentityQueueHelpers;
const {
  handleIdentityProgressDoc,
  completePreviousIdentityDocs,
} = useIdentityProgressHelpers;
const { getRequest, postRequest } = useApiHelpers();
const { getOrderId } = useOrderEndpoints();
const { hasAxnabInQueue, isAxnab, handleAxnabModal } = useAxnab();

export const useIdentityStore = defineStore({
  id: 'identityStore',
  state: () => ({
    identityRoutes: checkLocalStorage('identityRoutes', []), // for ID queue
    queueCounter: checkLocalStorage('queueCounter', 1),
    totalSteps: checkLocalStorage('totalSteps', 1),
    requiresDocRecollection: checkLocalStorage('requiresDocRecollection', false),
    hasSubmittedToSumsub: false,
    identityProgress: checkLocalStorage('identityProgress', []), // for ID dashboard
    currentIdentityView: '',
  }),
  actions: {
    async fetchIdentityDocuments() {
      const { data } = await getPendingIdentityDocuments();
      if (hasAxnabInQueue(data)) {
        handleAxnabModal();
        return;
      }
      return data;
    },
    async setupIdentityQueue(fromPath) {
      const data = await this.fetchIdentityDocuments();
      const newDocuments = [];

      return new Promise((res, rej) => {
        if (!data) {
          rej(data);
          return;
        }

        Object.entries(data).forEach(([key, value], index) => {
          organizeRoutes(
            this,
            key,
            value,
            newDocuments,
            index,
          );
        });

        // 1st condition: from non identity pages, we can call the queue and it
        // should override the queue array
        // 2nd condition: from sumsub pages we can have the queue overridden
        // by sumsub recollection requirement
        // 3rd condition: we have payment based exceptions like dlocal which can
        // trigger the queue again to show a dlocal based page

        if (
          (fromPath && !fromPath.includes('identity'))
          || this.requiresDocRecollection
          || toResetIdentityQueue.value
        ) {
          this.identityRoutes = newDocuments;
          this.resetCounter();
        }
        this.identityRoutes = sortRoutes(this.identityRoutes);
        this.calculateTotalSteps();
        bindLocalStorage('identityRoutes', this.identityRoutes);
        res(data);
      });
    },
    calculateTotalSteps() {
      const numberOfBanxaSteps = this.identityRoutes.reduce((acc, identity) => {
        if (identity.provider.toUpperCase() === 'BANXA') {
          acc += 1;
        }
        return acc;
      }, 0);

      // we count sumsub and plaid items as 1 as all documents are collected in one page
      const numberOfSumsubSteps = this.identityRoutes.some(
        (identity) => identity.provider.toUpperCase() === 'SUMSUB',
      ) ? 1
        : 0;
      const numberOfPlaidSteps = this.identityRoutes.some(
        (identity) => identity.provider.toUpperCase() === 'PLAID',
      ) ? 1
        : 0;
      const numberOfIDVSteps = numberOfSumsubSteps + numberOfPlaidSteps;
      const totalSteps = numberOfBanxaSteps + numberOfIDVSteps;

      if (totalSteps === 0) {
        this.totalSteps = 1;
        return;
      }

      this.totalSteps = numberOfBanxaSteps + numberOfIDVSteps;
      bindLocalStorage('totalSteps', this.totalSteps);
    },
    async preFetchNextRoute(route = null) {
      if (await useLegacyIdentity()) {
        // There might be occasion where we receive two payloads from
        // sumsub. In that case, for the second payload, we have to make
        // sure we don't update the queue counter.
        if (this.hasSubmittedToSumsub && route?.name.includes('sumsub')) {
          return;
        }
        this.queueCounter += 1;
        bindLocalStorage('queueCounter', this.queueCounter);
        return;
      }

      if (route.name.includes('sumsub')) {
        this.identityProgress.map((identityDoc) => {
          if (identityDoc.url.includes('sumsub')) {
            identityDoc.status = 'completed';
          }
          return identityDoc;
        });
      } else if (route.name.includes('plaid')) {
        this.identityProgress.map((identityDoc) => {
          if (identityDoc.url.includes('plaid')) {
            identityDoc.status = 'completed';
          }
          return identityDoc;
        });
      } else {
        this.identityProgress.map((identityDoc) => {
          if (identityDoc.title === this.currentIdentityView) {
            identityDoc.status = 'completed';
          }
          return identityDoc;
        });
      }

      bindLocalStorage('identityProgress', this.identityProgress);
    },
    setCurrentIdentityPageStatus(status) {
      const docToMarkSubmitting = this.identityProgress.filter(
        (identityDoc) => identityDoc.title === this.currentIdentityView,
      );

      if (docToMarkSubmitting.length > 0) {
        docToMarkSubmitting[0].status = status;
      }
      bindLocalStorage('identityProgress', this.identityProgress);
    },
    updateCurrentIdentityView(currentView) {
      this.currentIdentityView = currentView;
    },
    updateToPreviousRoute() {
      this.queueCounter -= 1;
      bindLocalStorage('queueCounter', this.queueCounter);
    },
    resetCounter() {
      this.queueCounter = 1;
      bindLocalStorage('queueCounter', this.queueCounter);
    },
    resetSteps() {
      this.totalSteps = 1;
      bindLocalStorage('totalSteps', this.totalSteps);
    },

    async handleDocumentRecollectionChange() {
      const orderStore = useOrderFormStore();
      const orderId = await getOrderId();
      const statusRes = await getRequest(`/status/${orderId}`);
      const paymentStatus = statusRes?.data?.data?.payment?.label;

      // skip routing to status page if payment is pending and route to payement page
      if ((paymentStatus && paymentStatus.toLowerCase().includes('pending')) || !orderId) {
        return '/payment';
      }
      return `/status/${orderId}`;
    },

    async fetchNextIdentityRoute(route) {
      const orderStore = useOrderFormStore();
      const verificationStore = useVerificationStore();
      const paymentRoute = '/payment'; // Push to payment and let the payment route deal with the routing.
      const identityHubRoute = '/identity/dashboard';
      const orderId = await getOrderId();

      // id queue (legacy)
      if (await useLegacyIdentity()) {
        return this.setupIdentityQueue(route?.path).then(async (identityDocs) => {
          const noRemainingIDDocsFromApi = !identityDocs || identityDocs.length <= 0;

          /*
            axnab throws a modal to which we want to prevent any routing in the background as
            it will redirect to a 3rd party link and we don't want to redirect to any other routes
          */
          if (hasAxnabInQueue(identityDocs)) {
            return;
          }

          if (
              noRemainingIDDocsFromApi
            || this.identityRoutes.length === 0
            || (orderStore.getActivePaymentByMode
              && this.queueCounter > this.totalSteps)
          ) {
            // on document recollection we route straight to /status page
            if (this.requiresDocRecollection) {
              const documentRecollectionRoute = await this.handleDocumentRecollectionChange();
              return documentRecollectionRoute;
            }

            // this is used to skip payment page from showing up twice on express checkout flow
            if (isExpressCheckoutEnabledForMerchAndOrder()
              && verificationStore.canSkipVerification
            ) {
              if (!orderId) {
                trackClarityEvent('EXPRESS_CHECKOUT - Order registered & Redirecting to status page');

                const res = await postRequest('/orders/express-payment/buy', {});
                const registeredOrderId = res.data.order.id;
                return `/status/${registeredOrderId}`;
              }

              trackClarityEvent('EXPRESS_CHECKOUT - Redirecting to status page');

              return `/status/${orderId}`;
            }

            return paymentRoute;
          }

          return this.identityRoutes[this.queueCounter - 1].url;
        }, () => {
          if (isAxnab()) {
            return;
          }
          return paymentRoute;
        });
      }

      // id dashboard

      const queueDocs = await this.initProgressStore();
      // axnab throws a modal to which we want to prevent any routing in the background
      // as it will redirect to a 3rd party link
      if (hasAxnabInQueue(queueDocs)) {
        return;
      }

      if (this.identityProgress.length <= 0) {
        if (isExpressCheckoutEnabledForMerchAndOrder() && verificationStore.canSkipVerification) {
          if (!orderId) {
            trackClarityEvent('EXPRESS_CHECKOUT - ID Dashboard, Order Registered & Redirecting to status page');

            const res = await postRequest('/orders/express-payment/buy', {});
            const registeredOrderId = res.data.order.id;
            return `/status/${registeredOrderId}`;
          }

          return `/status/${orderId}`;
        }
        return paymentRoute;
      }
      if (this.identityProgress.getBlockedIdentity) {
        return this.identityProgress.getBlockedIdentity?.url;
      }
        return identityHubRoute;
    },
    toggleDocumentRecollection(isRequired) {
      this.requiresDocRecollection = isRequired;
      bindLocalStorage('requiresDocRecollection', this.requiresDocRecollection);
    },

    async initProgressStore() {
      const data = await this.fetchIdentityDocuments();
      const newQueueDocs = Object.entries(data);

      newQueueDocs.forEach(([key, value], index) => {
        handleIdentityProgressDoc(
          this,
					key,
					value,
					index,
				);
			});

      // check for docs after queue is called and then prev doocs are to be set as completed
      if (this.currentIdentityView) {
        completePreviousIdentityDocs(this.identityProgress, newQueueDocs);
      }

      bindLocalStorage('identityProgress', this.identityProgress);
      return newQueueDocs;
    },
    resetIdentityProgress() {
      this.identityProgress = [];
      removeLocalStorageByKey('identityProgress');
    },
    resetIdentityQueue() {
      this.identitProgress = [];
      removeLocalStorageByKey('identityQueue');
    },
    changeIdentityStatus(idDoc, status) {
      idDoc.status = status;
    },
  },
  getters: {
    canContinueToPayment() {
      return this.identityProgress
        && this.identityProgress.length > 0
        && this.identityProgress.every(
          (doc) => !INCOMPLETE_STATUES.includes(doc.status),
      );
    },
    getBlockedIdentity() {
      const blockedIdentityExists = this.identityProgress.find(
        (docs) => IDENTITY_BLOCKERS.includes(docs.centryKey),
      );
      return blockedIdentityExists;
    },

    async userHasPaidOnce() {
      if (!this.canContinueToPayment) {
        return false;
      }

      const orderId = await getOrderId();
      if (!orderId) {
        return false;
      }
      const statusRes = await getRequest(`/status/${orderId}`);
      const paymentStatus = statusRes?.data?.data?.status?.payment?.label;
      const paymentIsIncomplete = !INCOMPLETE_PAYMENT_STATUES.some(
        (status) => paymentStatus.toLowerCase().includes(status),
      );
      if (paymentStatus && paymentIsIncomplete) {
        return true;
      }

      return false;
    },

    getIdentityProgressByRoute: (store) => (identityRoute) => {
      if (!identityRoute || store.identityProgress.length <= 0) {
        return false;
      }

      return store.identityProgress.find(
        (idDoc) => idDoc?.url.toLowerCase() === identityRoute.toLowerCase(),
      );
    },
  },
});
