/* eslint-disable import/no-named-as-default */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { gsap } from 'gsap/gsap-core';
import maska from 'maska';
import VueClipboard from 'vue3-clipboard';
import { SnackbarService, Vue3Snackbar } from '@/packages/vue3-snackbar';
import i18n from '@/packages/i18n';
import http from '@/composables/api/http';

import useRefreshLocalStorage from '@/composables/storage/useRefreshLocalStorage';
import initDatadog from '@/packages/third-party/datadog';
import App from './App.vue';
import router from './router';

initDatadog();

const app = createApp(App);
const { refreshLocalStorage } = useRefreshLocalStorage();

app.provide('http', http);
app.provide('$gsap', gsap);
app.use(maska);
app.use(createPinia());
app.use(router);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
app.use(SnackbarService);
app.component('vue3-snackbar', Vue3Snackbar);
app.mount('#space');
app.use(i18n);

if (
  import.meta.env.VITE_ENV !== 'local'
  && import.meta.env.VITE_ENV !== 'development'
) {
  refreshLocalStorage();
}
