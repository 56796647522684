import useApiHelpers from '@/composables/api/helpers';

const { getRequest, postRequest } = useApiHelpers();

export default () => {
  const getSiftData = () => new Promise((resolve, reject) => {
    getRequest('/sift').then(
      (response) => resolve(response.data),
    ).catch(reject);
  });

  const sendNsureDeviceId = (payload) => postRequest('/nsure/deviceId', payload, false, false, {}, false);

  return {
    getSiftData,
    sendNsureDeviceId,
  };
};
