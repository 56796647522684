export default function useScriptTagSetup() {
  const setupScriptTag = (jsUrl, callback = '') => {
    const tagElem = document.createElement('script');

    tagElem.setAttribute('src', jsUrl);
    document.head.appendChild(tagElem);

    return new Promise((resolve, reject) => {
      // Error loading
      tagElem.onerror = () => {
        console.error('Error loading script tag ', tagElem);
        reject(tagElem);
      };

      if (callback) {
        tagElem.addEventListener('load', callback);
      }

      tagElem.onload = () => resolve(tagElem);
    });
  };

  const setupLinkTag = (cssUrl, callback) => {
    const linkElem = document.createElement('link');
    linkElem.setAttribute('href', cssUrl);
    linkElem.setAttribute('rel', 'stylesheet');

    document.head.appendChild(linkElem);

    return new Promise((resolve, reject) => {
      // Error loading
      linkElem.onerror = () => {
        console.error('Error loading link tag ', linkElem);
        reject();
      };

      linkElem.addEventListener('load', callback);
      resolve();
    });
  };

  return {
    setupScriptTag,
    setupLinkTag,
  };
}
