<template>
  <img
    :alt="`${brand} logo`"
    :src="imageSources[brand][inverseTheme]"
    class="logo logo-header__banxa__img"
    width="86"
    height="14"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useGlobalStore } from '@/stores/globalStore';
import useAxnab from '@/composables/identity/useAxnab';
import banxaLogoDark from '@/assets/images/logos/banxa-logo-dark.png';
import banxaLogoLight from '@/assets/images/logos/banxa-logo-light.png';
import axnabLogoDark from '@/assets/images/logos/axnab-logo-dark.png';
import axnabLogoLight from '@/assets/images/logos/axnab-logo-light.png';

const { isAxnab } = useAxnab();
const globalStore = useGlobalStore();
// Inverse theme so we show the correct logo (i.e. dark theme shows light logo)
const inverseTheme = computed(() => (globalStore?.global?.theme && globalStore?.global?.theme.toLowerCase() === 'light' ? 'dark' : 'light'));
const brand = computed(() => (isAxnab() ? 'axnab' : 'banxa'));
const imageSources = {
  banxa: {
    dark: banxaLogoDark,
    light: banxaLogoLight,
  },
  axnab: {
    dark: axnabLogoDark,
    light: axnabLogoLight,
  },
};
</script>
