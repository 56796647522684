/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

export const useModalStore = defineStore({
  id: 'modal',
  state: () => ({
    actionModal: {
      toShow: false,
      options: {
        title: '',
        text: '',
        primaryBtnText: null,
        primaryBtnAction: null,
        secondaryBtnText: null,
        secondaryBtnAction: null,
        alignment: {},
      },
    },

    walletModal: {
      toShow: false,
      options: {
        primaryBtnAction: null,
        secondaryBtnAction: null,
        alignment: {},
      },
    },

    formComponentModal: {
      toShow: false,
      options: {
        primaryBtnAction: null,
        secondaryBtnAction: null,
        componentPath: '',
        alignment: {
          overflow: 'scroll',
          'justify-content': 'center',
          'align-content': 'start',
        },
      },
    },

    dofpFailureModal: {
      toShow: false,
      options: {
        body: '',
        alignment: {
          'justify-content': 'center',
          'align-content': 'center',
        },
      },
    },

    dofpNotifyModal: {
      toShow: false,
      options: {
        primaryBtnText: null,
        primaryBtnAction: null,
        secondaryBtnText: null,
        secondaryBtnAction: null,
        alignment: {},
      },
    },

    orderRecoveryModal: {
      toShow: false,
      options: {
        primaryBtnAction: null,
        secondaryBtnAction: null,
        alignment: {
          'justify-content': 'center',
          'align-content': 'center',
        },
      },
    },

    successModal: { toShow: false, progressLevel: '' },
    redirectModal: {
      toShow: false,
      options: {
        alignment: {
          'justify-content': 'center',
          'align-content': 'center',
        },
      },
    },
  }),
  actions: {

    /**
     * Toggles the visibility of the wallet modal.
     *
     * @param {boolean} toShow - Whether the modal should be visible or not.
     */

    toggleWalletModal(toShow) {
      this.walletModal.toShow = toShow;
    },
  },
  getters: {
    getActionModalData(store) {
      return store.actionModal;
    },
    getSuccessModalVisibility(store) {
      return store.successModal.toShow;
    },
    showModal() {
      const globalModalToShow = Object.keys(this.$state).filter(
        (modal) => this.$state[modal].toShow === true,
      ).length > 0;

      return globalModalToShow;
    },
  },
});
