import { createI18n } from 'vue-i18n';

import useAxnab from '@/composables/identity/useAxnab';

import numberFormats from './rules/numberFormats';
import datetimeFormats from './rules/datetimeFormats';
import en from './locales/en.json';

const DEFAULT_LOCALE = 'en';

const { isAxnab } = useAxnab();

export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: { en },
  datetimeFormats,
  numberFormats,
  warnHtmlMessage: false, // disable of the Detected HTML in message
});

const valueIncludesBanxa = (val) => val.toLowerCase().includes('banxa');

const replaceBanxaWithAxnab = (val) => val.replace(/banxa/gi, (match) => {
  const replacement = 'axnab';

  if (match === match.toUpperCase()) {
    return replacement.toUpperCase();
  }

  if (match === match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()) {
    return replacement.charAt(0).toUpperCase() + replacement.slice(1);
  }

  return replacement;
});

const originalT = i18n.global.t.bind(i18n.global);

i18n.global.t = (key, ...args) => {
  const translation = originalT(key, ...args);

  if (isAxnab() && valueIncludesBanxa(translation)) {
    return replaceBanxaWithAxnab(translation);
  }
  return translation;
};

export { valueIncludesBanxa, replaceBanxaWithAxnab };

export default i18n;
