import { defineStore } from 'pinia';

export const useErrorStore = defineStore({
    id: 'error',
    state: () => ({
        error: {
            code: null,
            message: null,
            traceId: null,
        },
    }),
    actions: {},
    getters: {
        getError(store) {
            return store.error;
        },
    },
});

// Path: src/stores/components/modalStore.js
