/* eslint-disable import/no-cycle */
import http from './http';
import useAuthToken from './useAuthToken';
import useErrorHandling from './useErrorHandling';

export default function useApiHelpers() {
  const delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

  const handleErrors = (error, throwOnError) => {
    const { handleServerErrors } = useErrorHandling();
    if (throwOnError) {
      handleServerErrors(error);
    }
  };

  const triggerHttpRequest = async (endpoint, token, callback) => {
    const response = await http.get(endpoint, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (callback) {
      callback(response);
    }
    return response;
  };

  const attemptRequest = async (endpoint, callback, options, token, attempts = 0) => {
    const {
      retryCount, throwOnError, retry, retryDelay,
    } = options;

    try {
      return await triggerHttpRequest(endpoint, token, callback);
    } catch (error) {
      if (retry && attempts < retryCount - 1) {
        await delay(retryDelay);
        return attemptRequest(endpoint, callback, options, token, attempts + 1);
      }

      handleErrors(error, throwOnError);
      throw error;
    }
  };

  const getRequest = async (endpoint, callback, options = {}) => {
    const defaultOptions = {
      throwOnError: true,
      retry: false,
      retryCount: 3,
      retryDelay: 500,
    };

    const mergedOptions = { ...defaultOptions, ...options };
    const { fetchAuthToken } = useAuthToken();
    const token = await fetchAuthToken();

    return attemptRequest(endpoint, callback, mergedOptions, token);
  };

  const postRequest = async (
    endpoint,
    payload,
    callbackOnSuccess = false,
    callbackOnError = false,
    opts = {
      headers: {},
    },
    throwOnError = true,
  ) => {
    const { handleServerErrors, handleMappedErrorResponses } = useErrorHandling();
    const { fetchAuthToken } = useAuthToken();
    try {
      const token = await fetchAuthToken();
      return new Promise((resolve, reject) => {
        http
          .post(endpoint, payload, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
              ...opts.headers,
            },
          })
          .then((response) => {
            resolve(response);

            if (callbackOnSuccess) {
              callbackOnSuccess(response);
            }
          })
          .catch((err) => {
            console.error(`${err}`);

            const {
              status,
              data: { code, errors },
            } = err.response;
            if (status === 422 && callbackOnError) {
              // Adding below if condition, because for 422 error,
              // we need to pass the failed response to the reject of the calling Promise
              if (typeof callbackOnError === 'function') {
                callbackOnError(
                  handleMappedErrorResponses(code, errors) || err?.response,
                );
              }
              reject(err?.response);
              return;
            }

            if (status === 419) {
              sessionStorage.clear();
            }

            // Redirect to an error page
            if (throwOnError === true) {
              handleServerErrors(err);
              /**
               * We should not pass the control to the component,
               * when we intentionally want to redirect the customer to an error page
               */
              // reject(err?.response);
              // Then end the process.
              return;
              // Because only validation errors should be handled by component
            }

            // In case we need to handle non-valiodation errors within component
            if (callbackOnError) {
              if (typeof callbackOnError === 'function') {
                callbackOnError(err?.response);
              }
              // Carry on the rejection to the calling function
              reject(err?.response);
            }
          });
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  const multiPostRequests = async (promises, callback) => {
    const { handleServerErrors } = useErrorHandling();

    try {
      return Promise.all(promises)
        .then((response) => {
          callback(response);
        })
        .catch((err) => {
          handleServerErrors(err);
        });
    } catch (err) {
      throw new Error(err);
    }
  };

  return {
    getRequest,
    postRequest,
    multiPostRequests,
  };
}
