/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useScriptTag } from '@vueuse/core';

import useThirdPartyScriptEndpoints from '@/composables/api/useThirdPartyScriptEndpoints';
import useScriptTagSetup from '@/composables/helpers/sciptTagSetup';

const { getSiftData } = useThirdPartyScriptEndpoints();
const { setupScriptTag } = useScriptTagSetup();

export default () => {
  const pushSiftData = (session_id, user_id, sift_beacon_key, resolvePromise) => {
    window._sift = window._sift || [];
    window._sift.push(['_setAccount', sift_beacon_key]);
    window._sift.push(['_setUserId', user_id]);
    window._sift.push(['_setSessionId', session_id]);
    window._sift.push(['_trackPageview']);
    resolvePromise();
  };

  const useSiftScript = () => new Promise((resolve, reject) => {
      getSiftData().then(({ session_id, user_id, sift_beacon_key }) => {
        const loadSift = () => {
          setupScriptTag('https://cdn.sift.com/s.js')
          .then(() => {
            pushSiftData(session_id, user_id, sift_beacon_key, resolve);
          })
          .catch(() => {
            pushSiftData(session_id, user_id, sift_beacon_key, resolve);
          });
        };

        // for pages that require Sift get request and to push to window object again
        // like on Verif Code
        if (window._sift) {
          pushSiftData(session_id, user_id, sift_beacon_key, resolve);
        } else {
          loadSift();
        }

        // if (window.attachEvent) {
        //   window.attachEvent('onload', loadSift);
        // } else {
        //   window.addEventListener('load', loadSift, false);
        // }
      }).catch({

        reject,
      });
    });

  const useGoogleMapsScript = (mountedCallback) => {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDvzXKisgpiVIVABXgVMdjVHgLoZwNZ38&libraries=places';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener('load', (e) => {
        if (mountedCallback) mountedCallback();
      });
    } else if (mountedCallback) mountedCallback();
  };

  return {
    useSiftScript,
    useGoogleMapsScript,
  };
};
