// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';

export default function initDatadog() {
    if (!import.meta.env.VITE_DATADOG_APPLICATION_ID || !import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
        console.warn('Datadog Application ID and Client Token are required to initialize Datadog RUM');
        return;
    }

    datadogRum.init({
        applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        env: import.meta.env.VITE_DATADOG_ENV || 'local',
        site: 'datadoghq.com',
        service: 'space',
        sessionSampleRate: 55, // 55% of sessions will be tracked
        sessionReplaySampleRate: 10, // 10% of sessions will have session replay enabled
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        // storeContextsAcrossPages: true, // https://docs.datadoghq.com/logs/log_collection/javascript/#contexts-life-cycle
        allowedTracingUrls: [
            (url) => ['banxa', 'axnab'].some((v) => url.includes(v)),
        ],
    });
}
